import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import partnerImg from '../images/partner1.jpg'

// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function Main() {
    const navigate = useNavigate();

    const goToPartnerArea = () => {
      navigate('/partnerArea');
    };

    return (
        <div className="flex flex-col md:flex-row">
            <div className="flex-1 p-4">
                <div>
                    <p className="px-4 text-3xl md:text-5xl font-bold font-raleway">
                        Seja um representante 
                    </p>
                    <p className="px-4 text-3xl md:text-5xl font-bold font-raleway">
                        do AiGarçom
                    </p>
                    <p className="mt-3 px-4 text-lg md:text-3xl font-raleway">
                        Conquiste novos clientes 
                    </p>
                    <p className="px-4 text-lg md:text-3xl font-raleway">
                        e melhore sua renda.
                    </p>
                    <p className="px-4 text-lg font-raleway">
                        Se você está buscando 
                    </p>
                    <p className="px-4 text-lg font-raleway">
                        uma renda extra e tem acesso a
                    </p>
                    <p className=" px-4 text-lg font-raleway">
                        bares e restaurantes em sua cidade,
                    </p>
                    <p className="px-4 text-lg font-raleway">
                        seja um representante do AiGarçom.
                    </p>
                    <p className="px-4 text-lg font-raleway">
                        Você tem toda autonomia 
                    </p>
                    <p className="px-4 text-lg font-raleway">
                        e escolhe o quanto quer ganhar. 
                    </p>
                    
                    <p className='ml-6 mt-6'>
                        <button onClick={goToPartnerArea} className="bg-blue-500 border-blue-500 text-white font-bold py-2 px-2 rounded">
                            Tenho interesse
                        </button>
                    </p>
                </div>
            </div>
            <div className="flex justify-center">
                <img src={partnerImg} alt="PartnerImage" className="w-[45vh] md:w-[600px]" />
            </div>

        </div>

    );
}

export default Main;