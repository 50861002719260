import React from 'react';
import { useNavigate } from 'react-router-dom';
import mainPageImg from '../images/mainpageimg.jpg'
// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function Main( { scrollToMoreDetails } ) {
    const navigate = useNavigate();

    const goToStartNow = () => {
      navigate('/startNow');
    };

    

    return (
        <div className="flex justify-center w-screen">
            <div className="flex flex-col md:flex-row space-y-10 md:space-y-0">
                <div className="flex flex-1 p-2">
                    <div>
                        <p className="px-4 text-3xl font-bold md:text-5xl font-raleway">
                            Aplicativo AiGarçom 
                        </p>
                        <p className="px-4 mt-2 text-2xl md:text-4xl font-raleway">
                            Descubra como otimizar o atendimento 
                        </p>
                        <p className="px-4 text-2xl md:text-4xl font-raleway">
                            do Seu Bar ou Restaurante
                        </p>

                        <p className="pt-2 px-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl font-raleway">
                            Maximize seu faturamento.
                        </p>
                        <p className="px-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl font-raleway">
                            Organize sua operação.
                        </p>
                        <p className="px-4 text-xl md:text-2xl lg:text-2xl xl:text-2xl font-raleway">
                            Ofereça uma experiência excepcional aos seus clientes.
                        </p>

                        <button
                            onClick={goToStartNow}
                            className="ml-6 mt-10 bg-blue-500 text-white font-bold py-2 px-4 rounded">
                            Comece agora
                        </button>

                        <button onClick={scrollToMoreDetails} className="ml-6 mt-4 bg-white-500 border border-blue-500 py-2 px-4 rounded">
                            Mais informações
                        </button>
                    </div>
                </div>
                <div className="flex justify-center">
                    <img src={mainPageImg} alt="Main" className="w-[45vh] md:w-[600px]" />
                </div>
            </div>
        </div>

    );
}

export default Main;